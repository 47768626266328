import './sprite'
import svg4everybody from 'svg4everybody'
import svgxuse from 'svgxuse'
import browser from 'jquery.browser'
import Test from './blocks/test'
import Share from './share'

svg4everybody();

// Put each block initialisation on new string like this
$(() => (new Test()).render())
// $(() => (new Block2()).render());

$(() => {

  $('#to-app').on('click', () => {
    let mode = 'chrome';
    const curLocation = window.location.href;

    console.log(curLocation);

    // predefined apps
    var options = {
      "chrome":{
        run: "googlechrome://navigate?url=" + curLocation,
        waitForApp: 2000,
        beforeStart: function(){

        },
        onAppNotInstalled: function(){
          window.location = 'https://play.google.com/store/apps/details?id=com.android.chrome&hl=ru';
        },
        onAppStart: function(){

        },
        onAppDone: function () {

        }
      }
    }

    // if no application defined in location.hash, then last app from predefined
    if (!(mode in options)) mode = Object.keys(options).slice(-1)[0];

    // magic for native app start
    (function(app){
      let blurHappened = false,
        waitForAppStart = app.onAppNotInstalled ? setTimeout( app.onAppNotInstalled, app.waitForApp || 3000 ) : null;

      window.onblur = function(){
        // console.log("BLUR happened - application exists");
        blurHappened = true;
        clearTimeout(waitForAppStart);
        app.onAppStart && app.onAppStart();
      }
      window.onfocus = function () {
        if (blurHappened) {
          // console.log("FOCUS returned back to me");
          window.onfocus = window.onblur = null;
          app.onAppDone && app.onAppDone();
        }
      }
      setTimeout(function(){
        // console.log("START");
        app.beforeStart && app.beforeStart();
        window.location = app.run;
      },10)

    })(options[mode])
  });

  function setSpecular() {
    $('.specular-left').css('width', Math.floor($(window).height() * 0.62));
    $('.specular-right').css('width', Math.floor($(window).height() * 0.62));
  }

  function setPreviewSize() {
    const innerH = $(window).innerHeight();
    const innerW = $(window).innerWidth();

    $('.video-preview').css({"width": innerW,"height": innerH});
  }

  $(window).on('load', () => {

    //alert(navigator.userAgent);

    if ($('.wrapper').hasClass('affluent')) {
      if (!($.browser.safari || $.browser.mozilla || $.browser.msie || $.browser.opera || $.browser.webkit) || RegExp('xiaomi', 'i').test(navigator.userAgent) || RegExp('SamsungBrowser', 'i').test(navigator.userAgent)){
        $('#attention').addClass('active');
        $('body').addClass('preload');
      } else {
        console.log('true');
      }
    } else {
      if (RegExp('SamsungBrowser', 'i').test(navigator.userAgent)){
        $('#attention').addClass('active');
        $('body').addClass('preload');
      } else {
        console.log('true');
      }
    }


    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      //alert('Hello Microsoft User!');
      $('.specular-left').addClass('specular-ms');
      $('.specular-right').addClass('specular-ms');
    }

    setSpecular();

    setPreviewSize();

  });

  $(window).on('resize', () => {
    setSpecular();
    setPreviewSize();
  });

  function setActiveStep(index) {
    $('.wrapper').find('.step.active').fadeOut();
    setTimeout(function () {
      $('.wrapper').find('.step.active').removeClass('active');
      $('.wrapper').find('[data-step=' + index +']').fadeIn().addClass('active');
    }, 510);
  }

  function setActiveAnimation() {
    let prevIndex = Number($('.step-video').find('.step-video__text.active').attr('data-animation'));
    let curIndex = prevIndex + 1;

    $('.step-video').find('[data-animation=' + prevIndex +']').fadeOut();

    setTimeout(function () {
      $('.step-video').find('[data-animation=' + prevIndex +']').removeClass('active');
      $('.step-video').find('[data-animation=' + curIndex +']').fadeIn().addClass('active');
    }, 510);
  }

  $('[data-step-action]').on('click', function () {
    const curIndex = $(this).attr('data-step-action');
    setActiveStep(curIndex);
  });

  $('.presents__card').on('click', function () {
    $(this).next('.presents-desc').fadeIn();
  });

  $('.presents-desc__close').on('click', function () {
    $(this).parent().parent().fadeOut();
  });

  $('.preloader__btn').on('click', function () {
    let video;

    if ($(window).width() > 1023) {
      video = document.getElementById('video');
    } else {
      if ($(window).width() > 767 && $(window).width() < 1025) {
        video = document.getElementById('video-tablet');
      } else {
        video = document.getElementById('video-mobile');
      }
    }
    if ($(window).width() < 1023) {
      setTimeout(function () {
        video.play();
      }, 100);
      setTimeout(function () {
        video.pause();
        video.currentTime = 0.001;
      }, 200);
    }
  });

  $(document).ready(function () {

    $(() => new Share().render());
  });

  (function(){

    let videoStatus = false;

    let vid;

    if ($(window).width() > 1023) {
      vid = document.getElementById('video');
    } else {
      if ($(window).width() > 767 && $(window).width() < 1025) {
        vid = document.getElementById('video-tablet');
      } else {
        vid = document.getElementById('video-mobile');
      }
    }

    //let vid = document.getElementById("video");

    vid.oncanplaythrough = function() {
      //alert("Can play through video without stopping");
      videoStatus = true;
      $('.preloader').fadeOut().removeClass('active');
    };

      let video;

      if ($(window).width() > 1023) {
        video = document.getElementById('video');
      } else {
        if ($(window).width() > 767 && $(window).width() < 1025) {
          video = document.getElementById('video-tablet');
        } else {
          video = document.getElementById('video-mobile');
        }
      }

      $(".play-video-btn").on('click ', function() {

        $(this).fadeOut();

        $('.box-video__bg').fadeOut();

        video.play();

        const prevIndex = Number($('.step-video').find('.step-video__text.active').attr('data-animation'));

        let timeout = 3750;

        if ($('.wrapper').hasClass('affluent')) {
          timeout = 4450;
        }

        if ($('.wrapper').hasClass('premium')) {
          timeout = 3600;
        }


        if (prevIndex == 1) {
          //  if ($(window).width() > 769) {
          setTimeout(function () {
            video.pause();
            setActiveAnimation();
          }, timeout);

        } else {
          setTimeout(function () {
            $('[ data-specular]').addClass('active');
          }, 1000);
          setTimeout(function () {
            setActiveAnimation();
          }, 3000);
        }
      });

    $('#openGift').on('click', () => {
      if (!videoStatus) {
        $('.preloader').fadeIn().addClass('active');
        $('body').addClass('preload');

        setTimeout(function () {
          if ($(window).width() < 769){
            $('.preloader__btn').click();
          }
        }, 500);
      }
    });
  })();
});





