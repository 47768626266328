export default class {
    constructor(options) {
        const defaults = {
            el: '[data-share]',
            dom: {
                button: '[data-share-button]',
            }
        };
        this.options = $.extend(true, {}, defaults, options);
        this.$window = $(window);
        this.$document = $(document);
        return this;
    }

    bindShareEvents() {
        $(this.options.el).each((i, el) => {
            const $el = $(el);
        const url = encodeURIComponent($el.data('url'));
        const title = encodeURIComponent($el.attr('data-title'));
        const image = encodeURIComponent($el.data('image'));
        const vkPopupUrl = `https://vk.com/share.php?url=${url}/?title=${title}&image=${image}`;
        const fbPopupUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
          const twPopupUrl = `http://twitter.com/share?url=${url}&title=${title}`;
        $(this.options.dom.button, $el).each((i, button) => {
            const $button = $(button);

        switch ($button.data('share-button')) {
            case 'fb':
                $button.attr('href', fbPopupUrl);
                break;
            case 'vk':
                $button.attr('href', vkPopupUrl);
                break;
          case 'tw':
            $button.attr('href', twPopupUrl);
            break;
        }
    });
    });
    }
    render() {
        this.bindShareEvents();
        this.$window.on('share.bindEvents', this.bindShareEvents.bind(this));
    }
}
