export default class {
  constructor() {
    this.options = {
      el: '[data-test-block]', // block selector, used to define context of the block
      dom: {
        // put here all block's dom elements
        title: '[data-block-title]',
        img: '[data-block-img]'
      },
      classes: {
        // put all class names here
        titleXl: 'title--xl'
      }
    }

    this.$el = (this.options.el instanceof $) ? this.options.el : $(this.options.el)

    return this
  }

  get() {
    // put here jquery instances of all dom elements
    this.$title = this.$el.find(this.options.dom.title)
    this.$img = this.$el.find(this.options.dom.img)

    return this
  }

  init() {
    // do initialisation stuff here
    console.log(this.$title.text())
    this.$title.addClass(this.options.classes.titleXl)
  }

  render() {
    if (this.$el.length) {
      // do pre-initialisation stuff here
      this
        .get()
        .init()
    }
  }
}
